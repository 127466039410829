import { Button, Col, Form, Input, InputNumber, Modal, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { addCategory, addPricing, deleteCategory, deletePricing, getCategoryList, getPricing, updateCategory, updatePricing } from "../../../services/dataService";
import { EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import Search from "antd/lib/input/Search";
import TextArea from "antd/lib/input/TextArea";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [categoryData, setFormData] = useState({
    categoryId: "",
    name: "",
    description: ""
  });
  const [deleteDialogVisible, toggleDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [categoryForm] = Form.useForm();
  const nameValue = Form.useWatch('name', categoryForm);
  const descriptionValue = Form.useWatch('description', categoryForm);

  const fetchData = async () => {
    const newData = await getCategoryList();
    setCategories(newData);
  };
  useEffect(() => {
    fetchData()
  }, []);

  const toggleForm = (event) => {
    resetForm();
    setFormVisible(!formVisible);
  }

  const resetForm = () => {
    console.log(categoryData)
    if (categoryData.categoryId) {
      categoryForm.setFieldsValue({
        name: categoryData.name,
        description: categoryData.description,
      })
    } else {
      categoryForm.resetFields();
      categoryForm.setFieldsValue({
        name: "",
        description: "",
      })
      setFormData({
        categoryId: "",
        name: "",
        description: ""
      })
    }
  }

  const cancelForm = event => {
    setFormVisible(false);
  }

  const submitForm = async (event) => {
    if (categoryData.categoryId) {
      // update product
      let finalData = {
        categoryId: categoryData.categoryId,
        name: nameValue,
        description: descriptionValue
      }
      console.log(finalData);
      const prodUpdRes = await updateCategory(finalData)
      fetchData();
      toggleForm();
    } else {
      // add product
      let finalData = {
        name: nameValue,
        description: descriptionValue
      }
      console.log(finalData);
      const newProdRes = await addCategory(finalData);
      if (newProdRes.id) {
        fetchData();
        toggleForm();
      } else {
        // show error alert
      }
    }
    setFormData({
      categoryId: "",
      name: "",
      description: ""
    })
  }

  const handleDeleteClick = (categoryId) => {
    setDeleteId(categoryId);
    toggleDeleteDialog(true);
  }
  const handleDeleteDialogClose = (event) => {
    setDeleteId(null);
    toggleDeleteDialog(false);
  }
  const deleteData = (event) => {
    deleteCategory(deleteId).then(f => {
      toggleDeleteDialog(false);
      fetchData();
    });
  }

  const handleEditClick = (categoryId) => {
    let categoryDetail = categories.find(f => f.categoryId == categoryId);
    if (!categoryDetail) {
      return;
    }
    console.log(categoryDetail)
    setFormVisible(true);
    setFormData({
      categoryId: categoryDetail.categoryId,
      name: categoryDetail.name,
      description: categoryDetail.description
    })
    categoryForm.setFieldsValue({
      name: categoryDetail.name,
      description: categoryDetail.description
    })
  }

  const columns = [
    {
      title: 'Category',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (rec) => (
        <Space size="middle">
          <a><EditOutlined style={{ color: "#6a89a1" }} onClick={(e) => {
            handleEditClick(rec.categoryId);
          }} /></a>
          <a><DeleteOutlined style={{ color: "#f44336" }} onClick={(ev) => {
            handleDeleteClick(rec.categoryId);
          }} /></a>
        </Space>
      ),
    },
  ];

  const formUi = (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <h5>Add New Category</h5>
        <div>
          <CloseOutlined style={{ position: "relative", top: "1px", cursor: "pointer" }} onClick={toggleForm} />
        </div>
      </div>
      <Form
        labelCol={{ span: 20 }}
        wrapperCol={{ span: 20 }}
        layout="vertical"
        form={categoryForm}
      >
        <Row>
          <Col xs={24} md={10}>
            <Form.Item label="Name" name="name">
              <Input placeholder="Enter category name" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <TextArea rows={4} placeholder={"Enter category description"} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ display: "flex", marginTop: "24px", justifyContent: "space-between" }}>
        <div>
          <Button onClick={() => { resetForm(false) }}>
            Reset
          </Button>
        </div>
        <div>
          <Button onClick={cancelForm}>
            Cancel
          </Button>
          <Button type="primary" onClick={submitForm} style={{ marginLeft: "10px" }}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );

  const tableUi = (
    <>
      <Row style={{ marginBottom: "24px" }} >
        <Col xs={24} md={12}>
          <Search placeholder="Search" style={{ width: 200 }} />
        </Col>
        <Col xs={24} md={12} style={{ textAlign: "right" }}>
          <Button type="primary" icon={<PlusOutlined />} onClick={toggleForm}>
            New
          </Button>
        </Col>
      </Row>
      <Table columns={columns} dataSource={categories} />
    </>
  );

  return (
    <>
      {!formVisible ? tableUi : formUi}
      <Modal
        title="Delete Category"
        open={deleteDialogVisible}
        onOk={deleteData}
        onCancel={handleDeleteDialogClose}
        okText="Delete"
      >
        <p>Are you sure you want to delete the selected category?</p>
      </Modal>
    </>
  )
}

export default Categories;