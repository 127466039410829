import React, { useEffect, useState } from "react";
import { Layout, Image, Row, Col, Tag, Typography, Button, Skeleton, Form, InputNumber, Input, Modal, Radio, Checkbox } from "antd";
import "./styles.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { getPricing, getProductDetail, newOrder } from "../../services/dataService";
import { PlusOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
import { Container } from "react-bootstrap";

const { Text } = Typography;
const ProductDetail = () => {
  const [loading, setLoading] = useState(true);
  const [productDetail, setProduct] = useState([]);
  const [pricingData, setPricingData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [unit, setUnit] = useState('box')
  const [order, setOrder] = useState([])
  const [orderForm] = Form.useForm();
  const metersValue = Form.useWatch('meters', orderForm);
  const customerNameValue = Form.useWatch('customerName', orderForm);
  const phoneNumberValue = Form.useWatch('phoneNumber', orderForm);
  const addressValue = Form.useWatch('address', orderForm);

  const search = window.location;
  const productId = search.pathname.split("/")[2];
  if (!productId) {
    window.location = "/"
  }
  const fetchProductDetail = async () => {
    const newData = await getProductDetail(productId);
    if (!newData) {
      window.location = "/"
    }
    setProduct(newData);
    setLoading(false);
  };
  const fetchPricingData = async () => {
    const newData = await getPricing();
    setPricingData(newData);
  };
  useEffect(() => {
    setLoading(true);
    window.scrollTo(0,0)
    fetchProductDetail()
    fetchPricingData()
  }, []);

  const toggleOrderForm = () => {
    setShowModal(!showModal)
  }

  const unitOptions = [
    {label: 'Box', value: 'box'},
    {label: 'Rolls', value: 'roll'}
  ]

  const submitForm = async () => {
    let finalData = {
      customerName: customerNameValue,
      address: addressValue,
      phoneNumber: phoneNumberValue,
      meters: metersValue,
      productName: productDetail.name
    }
    if (
      !finalData.customerName ||
      !finalData.address ||
      !finalData.phoneNumber ||
      !finalData.meters ||
      !finalData.productName
    ) {
      return;
    }
    toggleOrderForm();
    orderForm.setFieldsValue({
      customerName: "",
      address: "",
      phoneNumber: "",
      meters: "",
      productName: productDetail.name
    })
  }

  return (
    <div>
      <Header style={{zIndex: showModal ? -1 : 12}} />
      <Layout className="layout white-bg">
        <Layout.Content>
          <Container className="white-bg">
            <Skeleton loading={loading} round={true} active >
              <br/>
              <br/>
              <div className="site-layout-content" style={{ borderRadius: "8px" }}>
                <Row style={{ marginBottom: "24px" }} >
                  <Col xs={24} md={7} className="me-4">
                    <Image
                      className="prod-img"
                      src={productDetail.image}
                      preview={false}
                    />
                  </Col>
                  <Col xs={24} md={16}>
                    <div className="mt-0" style={{ fontSize: "28px", marginBottom: "10px", fontWeight: "bold", color: '#222' }}>
                      {productDetail.name}
                    </div>
                    <div style={{ fontSize: "14px" }}>
                      <div style={{ marginBottom: "12px" }}>
                        <span style={{ fontWeight: "600", marginRight: "5px" }}>
                          Category:
                        </span>
                        {productDetail.categoryName}
                      </div>
                      <div style={{ marginBottom: "24px" }}>
                        <span style={{ fontWeight: "500", fontSize: "18px" }}>Available Sizes: </span>
                        <br />
                        {productDetail.sizes && productDetail.sizes.map((f, index) => <Tag color={"#139348"} key={index + "nothingKey"} style={{ fontSize: "14px", marginBottom: "12px" }}>{f}</Tag>)}
                      </div>
                      <div style={{ marginBottom: "24px" }}>
                        <span style={{ fontWeight: "600" }}>Description</span>
                        <br />
                        {console.log(productDetail)}
                        <Text style={{whiteSpace: 'pre-line'}}>
                          {productDetail.description}
                        </Text>
                      </div>
                      <div>
                        <Button type="primary" onClick={toggleOrderForm} style={{backgroundColor: '#139348', borderColor: '#139348'}}>
                          <PlusOutlined style={{ position: 'relative', top: "-2px" }} />
                          Order now
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br/>
                <hr />
                <Row style={{ marginBottom: "24px" }} >
                <span className="text-center price-heading">Price List</span>
                <div className="mobile-hidden">
                  <table className="table table-striped table-hover table-bordered price-table" style={{borderColor: '#222'}}>
                    <thead>
                      <tr style={{backgroundColor: '#70c04f', color: '#222'}}>
                        <th width="50" style={{padding: 10}} scope="col">#</th>
                        <th width="100" style={{padding: 10}} scope="col">METERS</th>
                        <th width="150" style={{padding: 10}} scope="col">AMOUNT (GST EXC.)</th>
                        <th width="150" style={{padding: 10}} scope="col">TAXABLE AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                    {pricingData?.map((item, index) => {
                      return (
                        <tr key={index + 'nohtingm'} style={{backgroundColor: index%2 === 0 ? '#ffd2d3' : 'rgb(163 174 237)'}}>
                          <th className="pricing-td" scope="row">{index + 1}</th>
                          <td className="pricing-td">{item.meters}</td>
                          <td className="pricing-td">{item.taxAmount}</td>
                          <td className="pricing-td">{item.boxRate}</td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                  </div>
                </Row>
              </div>
            </Skeleton>
          </Container>
        </Layout.Content>
        <Modal
          title="Order Now"
          centered
          open={showModal}
          closable={false}
          onOk={() => submitForm()}
          onCancel={() => setShowModal(false)}
          width="80%"
          okText="Submit"
          okButtonProps={{style: {backgroundColor: '#139348', borderColor: '#139348'}}}
          style={{marginBottom: '5rem', marginTop: '5rem'}}
          footer={false}
        >
          <Form
          labelCol={{ span: 20 }}
          wrapperCol={{ span: 20 }}
          layout="vertical"
          form={orderForm}
          className="order-form"
        >
          <Row>
            <Col xs={24} md={12}>
              <Form.Item label="Name" required name="customerName">
                <Input required placeholder="Enter name here" autoComplete="off" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item required label="Phone no." name="phoneNumber">
                <Input required addonBefore={"+91"} placeholder="Enter phone number here" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item required label="Select what do you want?">
                <Radio.Group
                  options={unitOptions}
                  onChange={({target: {value}}) => {
                    setUnit(value)
                  }}
                  value={unit}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Form.Item required label={`Enter number of ${unit === 'box' ? 'Boxes' : 'Rolls'}`} name="meters">
                <InputNumber required placeholder={`Enter number of ${unit === 'box' ? 'Boxes' : 'Rolls'}`} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item required label="Product">
                <Input disabled value={productDetail.name} placeholder="Enter name here" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item required label="Address" name="address">
                <TextArea required rows={4} style={{ resize: "none" }} placeholder="Enter complete address" />
              </Form.Item>
            </Col>
          </Row>
            <div>
              <p>* Select length for order</p>
              <div className="mobile-hidden">
              <table className="table table-striped table-hover table-bordered price-tables" style={{borderColor: '#222'}}>
                  <thead>
                    <tr style={{backgroundColor: '#70c04f', color: '#222'}}>
                      <th width="50" style={{padding: 10}} scope="col">#</th>
                      <th width="50" style={{padding: 10}} scope="col">Sr No</th>
                      <th width="100" style={{padding: 10}} scope="col">METERS</th>
                      <th width="150" style={{padding: 10}} scope="col">AMOUNT (GST EXC.)</th>
                      <th width="150" style={{padding: 10}} scope="col">TAXABLE AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                  {pricingData?.map((item, index) => {
                    return (
                      <tr key={item.meters + ' ' + index} style={{backgroundColor: index%2 === 0 ? '#ffd2d3' : 'rgb(163 174 237)'}}>
                        <th key={index + 'thKey'}>
                          <Checkbox onChange={({target: {checked}}) =>{
                            if(!checked){
                              const index = order.indexOf(item.pricingId)
                              const latOrder = JSON.parse(JSON.stringify(order))
                              if(index !== -1){
                                latOrder.splice(index, 1)
                                setOrder(latOrder)
                              }
                            } else {
                              const latOrder = JSON.parse(JSON.stringify(order))
                              latOrder.push(item.pricingId)
                              setOrder(latOrder)
                            }
                          }} />
                        </th>
                        <th className="pricing-td" scope="row">{index + 1}</th>
                        <td className="pricing-td">{item.meters}</td>
                        <td className="pricing-td">{item.taxAmount}</td>
                        <td className="pricing-td">{item.boxRate}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
                </div>
            </div>
            <Form.Item>
              <Button type="secondary" style={{marginRight: 20}} onClick={() => {setShowModal(false);
              orderForm.resetFields()}}>
                Cancel
              </Button>
              <Input 
                style={{backgroundColor: '#139348', color: '#fff', borderColor: '#319348', width: 100}} 
                type="submit" title="Submit" value="Submit" onClick={submitForm} />
            </Form.Item>
        </Form>
        </Modal>
      </Layout>
      <Footer />
    </div >
  );
};

export default ProductDetail;
