import { Button, Col, Form, InputNumber, Modal, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { addPricing, deletePricing, getContactRequests, getPricing, updatePricing } from "../../../services/dataService";
import { EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import Search from "antd/lib/input/Search";

function ContactRequest() {
  const [contactRequests, setData] = useState([]);
  const fetchData = async () => {
    const newData = await getContactRequests();
    setData(newData);
  };
  useEffect(() => {
    fetchData()
  }, []);

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (rec) => (
        <>
          <a>{rec.name}</a>
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (text) => <a>{text}</a>,
    },
  ];

  const tableUi = (
    <>
      <Row style={{ marginBottom: "24px" }} >
        <Col xs={24} md={12}>
          <Search placeholder="Search" style={{ width: 200 }} />
        </Col>
      </Row>
      <Table columns={columns} dataSource={contactRequests} />
    </>
  );

  return (
    <>
      {tableUi}
    </>
  )
}

export default ContactRequest;