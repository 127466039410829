const COLLECTIONS = {
    PRODUCTS: "products",
    CATEGORIES: "categories",
    SIZES: "sizes",
    PRODUCT_PRICING: "productPricing",
    ORDERS: "orders",
    CONTACT_REQUESTS: "contactRequests",
    SOCIAL: "social"
}

export { COLLECTIONS };