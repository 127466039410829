import { Col, Modal, Popconfirm, Row, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { getOrders, markOrderDelivered } from "../../../services/dataService";
import { DeliveredProcedureOutlined, PhoneOutlined } from '@ant-design/icons';
import Search from "antd/lib/input/Search";

function Orders() {
  const [orders, setPricing] = useState([]);

  const fetchOrders = async () => {
    const newData = await getOrders();
    setPricing(newData);
  };
  useEffect(() => {
    fetchOrders()
  }, []);

  const markDelivered = (orderId) => {
    markOrderDelivered(orderId).then(() => {
      fetchOrders();
    })
  }

  const getOrderDate = (datee) => {
    let dt = new Date(datee);
    return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes();
  }
  const columns = [
    {
      title: 'Customer',
      key: 'customerName',
      render: (rec) => (
        <>
          <a>{rec.customerName}</a>
        </>
      ),
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Meters',
      dataIndex: 'meters',
      key: 'meters',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Contact',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (text) => <a><PhoneOutlined style={{color: "blue"}}/> {text}</a>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Ordered On',
      key: 'orderedAt',
      render: (rec) => (
        <>
          <a>{getOrderDate(rec.orderedAt)}</a>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (rec) => (
        <Space size="middle">
          {
            !rec.delivered
              ? <Popconfirm placement="topRight" title="Are you sure, you want to mark order delivered?" okText="Yes" cancelText="No" onConfirm={() => {
                markDelivered(rec.orderId)
              }}>
                <Tag color="gold">New</Tag>
                <DeliveredProcedureOutlined title="Mark delivered" style={{ color: "#6a89a1", cursor: "pointer" }} />
              </Popconfirm>
              : <Tag color="green">Delivered</Tag>
          }
        </Space>
      ),
    },
  ];

  const tableUi = (
    <>
      <Row style={{ marginBottom: "24px" }} >
        <Col xs={24} md={12}>
          <Search placeholder="Search" style={{ width: 200 }} />
        </Col>
      </Row>
      <Table columns={columns} dataSource={orders} />
    </>
  );

  return (
    <>
      {tableUi}
    </>
  )
}

export default Orders;