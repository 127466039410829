// JavaScript
// src.firebase.js
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyDBoLlpPZVzhfpzIm-pSnVVsaDT1czN-es",
    authDomain: "marutiindustries-55d21.firebaseapp.com",
    projectId: "marutiindustries-55d21",
    storageBucket: "marutiindustries-55d21.appspot.com",
    messagingSenderId: "803636217950",
    appId: "1:803636217950:web:ea06e0cd37f433ce900ccf",
    measurementId: "G-PEGMRFVDDZ"
  };

// Initialize Firebase and Firestore
const FIREBASE_APP = initializeApp(firebaseConfig)
const FIRESTORE = getFirestore(FIREBASE_APP)
export { FIREBASE_APP, FIRESTORE }