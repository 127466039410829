import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, serverTimestamp, updateDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes, listAll, deleteObject } from "firebase/storage";
import { FIRESTORE } from "../firebase";
import { COLLECTIONS } from "../utils/enum";

export const getProductsList = async () => {
  const products = await getDocs(collection(FIRESTORE, COLLECTIONS.PRODUCTS));
  const finalProducts = [];
  const storage = getStorage();
  products.forEach((doc, index) => {
    const data = doc.data();
    finalProducts.push({
      id: doc.id,
      productId: doc.id,
      categoryId: data.categoryId.id,
      name: data.name,
      imageUrl: data.imageUrl
    });
  });
  const proms = [];
  function setAllUrls(prod, storageRef) {
    return new Promise((resolve, reject) => {
      getDownloadURL(storageRef).then((ff) => {
        if (ff) {
          const product = finalProducts.find((f) => f.productId === prod.productId);
          product.image = ff;
          resolve();
        } else {
          reject();
        }
      }, (onErr) => {
        console.log(onErr)
      });
    });
  }
  finalProducts.forEach((prod) => {
    let imgRef = ref(storage, `images/${prod.imageUrl}`);
    if (imgRef) {
      proms.push(setAllUrls(prod, imgRef));
    }
  });
  await Promise.all(proms);
  return finalProducts;
}
export const getProdList = async () => {
  const products = await getDocs(collection(FIRESTORE, COLLECTIONS.PRODUCTS));
  const productData = [];
  products.forEach((doc) => {
    const data = doc.data();
    productData.push({
      productId: doc.id,
      name: data.name
    });
  });
  return productData;
}

export const getProducts = async () => {
  const categories = await getDocs(collection(FIRESTORE, COLLECTIONS.CATEGORIES));
  const products = await getDocs(collection(FIRESTORE, COLLECTIONS.PRODUCTS));
  const finalProducts = [];
  const sizes = await getDocs(collection(FIRESTORE, COLLECTIONS.SIZES));
  const storage = getStorage();
  const categoriesData = [];
  const sizesData = [];
  categories.forEach((doc) => {
    const data = doc.data();
    categoriesData.push({
      categoryId: doc.id,
      title: data.name,
    });
  });
  sizes.forEach((doc) => {
    const data = doc.data();
    sizesData.push({
      sizeId: doc.id,
      size: data.size,
      order: data.order
    });
  });
  sizesData.sort((a, b) => a.order - b.order);

  const getProductSizeList = (sizeIds, namesArr) => {
    let sizes = [];
    sizeIds.forEach(el => {
      let found = sizesData.find(f => f.sizeId === el.id);
      if (found) {
        sizes.push(found.size);
      }
    });
    if (namesArr) {
      return sizes;
    }
    return sizes.length ? sizes.join(", ") : "";
  }
  products.forEach((doc, index) => {
    const data = doc.data();
    const cate = categoriesData.find((f) => f.categoryId === data.categoryId.id);
    if (cate) {
      finalProducts.push({
        id: doc.id,
        productId: doc.id,
        categoryId: data.categoryId.id,
        categoryName: cate.title,
        name: data.name,
        description: data.description,
        imageUrl: data.imageUrl,
        sizeIds: data.sizeId.map(f => f.id),
        sizeNames: getProductSizeList(data.sizeId, true)
      });
    }
  });
  const proms = [];
  function setAllUrls(prod, storageRef) {
    return new Promise((resolve, reject) => {
      getDownloadURL(storageRef).then((ff) => {
        if (ff) {
          const product = finalProducts.find((f) => f.productId === prod.productId);
          product.image = ff;
          resolve();
        } else {
          reject();
        }
      }, (onErr) => {
        console.log(onErr)
      });
    });
  }
  finalProducts.forEach((prod) => {
    let imgRef = ref(storage, `images/${prod.imageUrl}`);
    if (imgRef) {
      proms.push(setAllUrls(prod, imgRef));
    }
  });
  await Promise.all(proms);
  return {
    products: finalProducts,
    categories: categoriesData,
    sizes: sizesData
  };
}

export const getSocial = async () => {
  const products = await getDocs(collection(FIRESTORE, COLLECTIONS.SOCIAL));
  const finalProducts = [];
  const storage = getStorage();

  products.forEach((doc, index) => {
    const data = doc.data();
      finalProducts.push({
        id: doc.id,
        imageUrl: data.imageUrl,
      });
  });
  const proms = [];
  function setAllUrls(prod, storageRef) {
    return new Promise((resolve, reject) => {
      getDownloadURL(storageRef).then((ff) => {
        if (ff) {
          const product = finalProducts.find((f) => f.id === prod.id);
          product.image = ff;
          resolve();
        } else {
          reject();
        }
      }, (onErr) => {
        console.log(onErr)
      });
    });
  }
  finalProducts.forEach((prod) => {
    let imgRef = ref(storage, `images/${prod.imageUrl}`);
    if (imgRef) {
      proms.push(setAllUrls(prod, imgRef));
    }
  });
  await Promise.all(proms);
  return {
    products: finalProducts
  };
}

export const getCategoryList = async () => {
  const categories = await getDocs(collection(FIRESTORE, COLLECTIONS.CATEGORIES));
  const categoriesData = [];
  categories.forEach((doc) => {
    const data = doc.data();
    categoriesData.push({
      categoryId: doc.id,
      name: data.name,
      description: data.description
    });
  });
  return categoriesData;
}

export const addSocialPost = async (productData) => {
  const storage = getStorage();
  const storageRef = ref(storage, "/images/" + productData.imageData.name);

  // 'file' comes from the Blob or File API
  let uploaded = await uploadBytes(storageRef, productData.imageData.file.originFileObj);
  return addDoc(collection(FIRESTORE, COLLECTIONS.SOCIAL), {
    imageUrl: uploaded.metadata.name,
  });
}

export const addProduct = async (productData) => {
  const storage = getStorage();
  const storageRef = ref(storage, "/images/" + productData.imageData.name);

  // 'file' comes from the Blob or File API
  let uploaded = await uploadBytes(storageRef, productData.imageData.file.originFileObj);
  return addDoc(collection(FIRESTORE, COLLECTIONS.PRODUCTS), {
    name: productData.name,
    description: productData.description || "",
    imageUrl: uploaded.metadata.name,
    categoryId: doc(FIRESTORE, "categories/" + productData.categoryId),
    sizeId: productData.sizeIds.map(f => doc(FIRESTORE, "sizes/", f)),
  });
}

export const updateProduct = async (productData) => {
  let uploaded
  if (productData.imageData.file) {
    const storage = getStorage();
    const storageRef = ref(storage, "/images/" + productData.imageData.name);

    // 'file' comes from the Blob or File API
    uploaded = await uploadBytes(storageRef, productData.imageData.file.originFileObj);
  }
  return updateDoc(doc(FIRESTORE, COLLECTIONS.PRODUCTS, productData.productId), {
    name: productData.name,
    description: productData.description || "",
    imageUrl: !uploaded ? productData.imageData.name : uploaded.metadata.name,
    categoryId: doc(FIRESTORE, COLLECTIONS.CATEGORIES + "/" + productData.categoryId),
    sizeId: productData.sizeIds.map(f => doc(FIRESTORE, "sizes/", f)),
  });
}

export const deleteProduct = async (productId) => {
  return deleteDoc(doc(FIRESTORE, COLLECTIONS.PRODUCTS + "/", productId))
}

export const getProductDetail = async (productId) => {
  const product = await getDoc(doc(FIRESTORE, COLLECTIONS.PRODUCTS, productId));
  if (!product) {
    return null;
  }
  const productData = product.data();
  const categories = await getDoc(doc(FIRESTORE, COLLECTIONS.CATEGORIES, productData.categoryId.id));
  const catDat = categories.data();
  const sizes = await getDocs(collection(FIRESTORE, COLLECTIONS.SIZES));
  const sizesData = [];
  const storage = getStorage();
  sizes.forEach((doc) => {
    const data = doc.data();
    sizesData.push({
      sizeId: doc.id,
      size: data.size,
    });
  });

  const getProductSizeList = (sizeIds, namesArr) => {
    let sizes = [];
    sizeIds.forEach(el => {
      let found = sizesData.find(f => f.sizeId === el.id);
      if (found) {
        sizes.push(found.size);
      }
    });
    if (namesArr) {
      return sizes;
    }
    return sizes.length ? sizes.join(", ") : "";
  }
  productData.categoryName = catDat.name;
  productData.sizes = getProductSizeList(productData.sizeId, true);
  if (productData.imageUrl) {
    productData.image = await getDownloadURL(ref(storage, `images/${productData.imageUrl}`))
  }
  return productData
};

export const getPricing = async () => {
  const pricing = await getDocs(collection(FIRESTORE, COLLECTIONS.PRODUCT_PRICING));
  const pricingData = [];
  pricing.forEach((doc) => {
    const data = doc.data();
    pricingData.push({
      pricingId: doc.id,
      meters: data.meters,
      taxAmount: data.taxAmount,
      boxRate: data.boxRate
    });
  });
  pricingData.sort((a, b) => a.meters - b.meters)
  return pricingData;
}

export const addPricing = async (pricingData) => {
  return addDoc(collection(FIRESTORE, COLLECTIONS.PRODUCT_PRICING), {
    meters: pricingData.meters,
    taxAmount: pricingData.taxAmount,
    boxRate: pricingData.boxRate
  });
}

export const updatePricing = async (pricingData) => {
  return updateDoc(doc(FIRESTORE, COLLECTIONS.PRODUCT_PRICING, pricingData.pricingId), {
    meters: pricingData.meters,
    taxAmount: pricingData.taxAmount,
    boxRate: pricingData.boxRate
  });
}

export const deletePricing = async (pricingId) => {
  return deleteDoc(doc(FIRESTORE, COLLECTIONS.PRODUCT_PRICING + "/", pricingId))
}

export const addCategory = async (categoryData) => {
  return addDoc(collection(FIRESTORE, COLLECTIONS.CATEGORIES), {
    name: categoryData.name,
    description: categoryData.description
  });
}

export const updateCategory = async (categoryData) => {
  return updateDoc(doc(FIRESTORE, COLLECTIONS.CATEGORIES, categoryData.categoryId), {
    name: categoryData.name,
    description: categoryData.description
  });
}

export const deleteCategory = async (categoryId) => {
  return deleteDoc(doc(FIRESTORE, COLLECTIONS.CATEGORIES + "/", categoryId))
}

export const newOrder = async (orderData) => {
  return addDoc(collection(FIRESTORE, COLLECTIONS.ORDERS), {
    customerName: orderData.customerName,
    address: orderData.address,
    phoneNumber: orderData.phoneNumber,
    meters: orderData.meters,
    productName: orderData.productName,
    delivered: false,
    orderedAt: serverTimestamp()
  });
}

export const getOrders = async () => {
  const orderss = await getDocs(collection(FIRESTORE, COLLECTIONS.ORDERS));
  const allData = [];
  orderss.forEach((doc) => {
    const data = doc.data();
    allData.push({
      orderId: doc.id,
      customerName: data.customerName,
      address: data.address,
      phoneNumber: data.phoneNumber,
      meters: data.meters,
      productName: data.productName,
      orderedAt: data.orderedAt.toDate().toString(),
      orderTime: data.orderedAt.toDate(),
      delivered: data.delivered
    });
  });
  allData.sort((a, b) => {
    let date1 = new Date(a.orderTime).getTime();
    let date2 = new Date(b.orderTime).getTime();

    if (date1 < date2) {
      return 1
    } else if (date1 > date2) {
      return -1
    } else {
      return 0
    }
  })
  return allData;
}
export const markOrderDelivered = async (orderId) => {
  return updateDoc(doc(FIRESTORE, COLLECTIONS.ORDERS, orderId), {
    delivered: true
  });
}

export const getCarausalImages = async () => {
  const storage = getStorage();

  // Create a reference under which you want to list
  const listRef = ref(storage, 'bannerImages');

  let allImages = [];

  function setAllUrls(storageRef) {
    return new Promise((resolve, reject) => {
      getDownloadURL(storageRef).then((ff) => {
        if (ff) {
          const img = allImages.find((f) => f.name === storageRef.name);
          img.imageUrl = ff;
          resolve();
        } else {
          reject();
        }
      }, (onErr) => {
        console.log(onErr)
      });
    });
  }
  // Find all the prefixes and items.
  let allList = await listAll(listRef);
  allList.items.forEach((itemRef) => {
    allImages.push({ name: itemRef.name });
  });
  const proms = [];

  allList.items.forEach((ii) => {
    proms.push(setAllUrls(ii));
  });
  await Promise.all(proms);
  return allImages;
}

export const uploadImage = async (imageData) => {
  const storage = getStorage();
  const storageRef = ref(storage, "/bannerImages/" + imageData.name);

  // 'file' comes from the Blob or File API
  let uploaded = await uploadBytes(storageRef, imageData);
  return uploaded;
}

export const deleteImageFromFB = async (imageName) => {
  const storage = getStorage();
  const imgRef = ref(storage, "/bannerImages/" + imageName);

  // Delete the file
  return await deleteObject(imgRef)
}

export const getContactRequests = async () => {
  const contactReqs = await getDocs(collection(FIRESTORE, COLLECTIONS.CONTACT_REQUESTS));
  const contactRequests = [];
  contactReqs.forEach((doc) => {
    const data = doc.data();
    contactRequests.push({
      contactRequestId: doc.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message
    });
  });
  return contactRequests;
}

export const newContactRequest = async (data) => {
  return addDoc(collection(FIRESTORE, COLLECTIONS.CONTACT_REQUESTS), {
    name: data.name ? data.name : "",
    email: data.email ? data.email : "",
    phone: data.phone ? data.phone : "",
    message: data.message ? data.message : ""
  });
}

