import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Typography 
} from "antd";
const { Content } = Layout;
const { Text } = Typography;

function SignIn() {
  const onFinish = (values) => {
    if (values.username == "admin" && values.password== "12345678") {
      window.localStorage.setItem("IS_ADLN", "a0d8945d46c7696c8c1bb9487e8a1bc4");
      window.location.reload();
    }
  };
  const onFinishFailed = (errorInfo) => {
  };
  return (
    <Layout className="layout" style={{ height: "100vh" }}>
      <Content style={{ padding: '60px 50px' }}>
        <Row>
          <Col xs={0} md={6}></Col>
          <Col xs={24} md={12}>
            <Card>
              <Image src="./images/logo.png" preview={false} className="logo" />
              <br/>
              <br/>
              <Text>Enter your credentials to login</Text>
              <br/>
              <br/>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
                layout="vertical"
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input placeholder="Enter username here" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter password here" />
                </Form.Item>

                <Form.Item
                  style={{ textAlign: "right" }}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col xs={0} md={6}></Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default SignIn;