import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import "./styles.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { getPricing } from "../../services/dataService";
import { Container } from "react-bootstrap";

const Pricing = () => {
  const [pricingData, setPricing] = useState([]);
  const fetchPricing = async () => {
    const newData = await getPricing();
    setPricing(newData);
  };
  useEffect(() => {
    fetchPricing()
    window.scrollTo(0,0)
  }, []);

  return (
    <div>
      <Header />
      <Layout className="layout">
        <Layout.Content>
        <div className="price-bg">
            <h1 level={2}>PRICE LIST</h1>
          </div>
          <div className="site-layout-content">
          <Container>
            {/* <h2 className="text-center price-heading">Price List</h2> */}
            <br />
            <div className="mobile-hidden">
            <table className="table table-striped table-hover table-bordered price-table" style={{borderColor: '#222'}}>
            <thead>
              <tr style={{backgroundColor: '#70c04f', color: '#222'}}>
                <th width="50" style={{padding: 10}} scope="col">#</th>
                <th width="100" style={{padding: 10}} scope="col">METERS</th>
                <th width="150" style={{padding: 10}} scope="col">AMOUNT (GST EXC.)</th>
                <th width="150" style={{padding: 10}} scope="col">TAXABLE AMOUNT</th>
              </tr>
            </thead>
            <tbody>
            {pricingData?.map((item, index) => {
              return (
                <tr style={{backgroundColor: index%2 === 0 ? '#ffd2d3' : 'rgb(163 174 237)'}}>
                  <th className="pricing-td" scope="row">{index + 1}</th>
                  <td className="pricing-td">{item.meters}</td>
                  <td className="pricing-td">{item.taxAmount}</td>
                  <td className="pricing-td">{item.boxRate}</td>
                </tr>
              )
            })}
            </tbody>
            </table>
            </div>
            <div>
              <br/><br/>
                <h5 style={{fontWeight: 'bold', marginBottom: 30}}>Terms & Conditions: </h5>
                <p className="priceTandC">Freight: Extra</p>
                <p className="priceTandC">Payment: Advance before dispatch</p>
                <p className="priceTandC">Colour: 20% Additional</p>
                <p className="priceTandC">Printing: Cylander charge - Rs 6000 /- per colour Additional</p>
                <p className="priceTandC">If 2.5" Rs 250/- extra per box</p>
                <p className="priceTandC">0.5": 288 peice in box</p>
                <p className="priceTandC">1" : 144 peice in box</p>
                <p className="priceTandC">2" : 72 peice in box</p>
                <p className="priceTandC">3" : 48 peice in box</p>
                <p style={{color: '#027f36'}} className="priceTandC">*All meter, All inches available</p>
                <br/>
                <br/>
            </div>
            </Container>
          </div>
        </Layout.Content>
      </Layout>
      <Footer />
    </div>
  );
};

export default Pricing;
