/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Image } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faBars, faClose, faPhone } from "@fortawesome/free-solid-svg-icons";

import {
  faFacebookF,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { getProdList } from "../../services/dataService";

const Header = ({style}) => {
  const [prodList, setProdList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const fetchList = async () => {
    const newData = await getProdList();
    setProdList(newData);
  };

  useEffect(() => {
    fetchList()
  }, []);

  return (
    <>
      <div className="header-top-bar">
        <Container>
          <Row>
            <Col md="10" sm="12">
              <FontAwesomeIcon icon={faPhone} /> &nbsp; <a style={{color: '#222', textDecoration: 'none'}} href="tel:+918387887888">+91-8387887888</a> / <a style={{color: '#222', textDecoration: 'none'}} href="tel:+918104792395">+91-8104792395</a>
            </Col>
            <Col md="2" sm="12" className="iconContainer">
              <a
                href="https://www.facebook.com/profile.php?id=100087847838119"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebookF} style={{color: '#222'}} />
              </a>
              <a
                href="https://www.instagram.com/mitape9/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} style={{color: '#222'}} />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=918387887888"
                target="_blank"
              >
                <FontAwesomeIcon icon={faWhatsapp} style={{color: '#222'}} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="text-center">
        <Image src="/images/logo.png" preview={false} className="logo-main" />
      </Container>
      <Navbar className="header" style={style} sticky="top" expand="md">
        <Container>
          <div className="mainDiv">
            <Link className="linkNav" to="/">Home</Link>
            <Link className="linkNav" to="/about">About Us</Link>
            <Link to="" className="linkNav" onMouseEnter={() => setVisible(true)}>Products <FontAwesomeIcon icon={faAngleDown} /></Link>
            {visible &&
            <div className="dropdownMenuContainer" onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} >
            {
                  prodList.map((product) => {
                    return (
                      <div className="dropdownItems">
                        <Link to={"/product/" + product.productId}>
                          {product.name}
                        </Link>
                      </div>
                    );
                  })
                }
            </div>
            }
            <Link to="/gallery" className="linkNav" onMouseEnter={() => setVisible(false)}>Gallery</Link>
            <Link to="/pricing" className="linkNav">Price List</Link>
            <Link to="/contact" className="linkNav">Contact Us</Link>
          </div>
          <Link to="/contact" className="contactUs">
              Contact Us&nbsp; <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </Container>
      </Navbar>
      <div id="menuToggle">
        <FontAwesomeIcon onClick={() => setShowMenu(!showMenu)} icon={faBars} fontSize={24} />
      </div>
      <div id="menuContainer" style={{
        opacity: !showMenu ? "0" : "1",
        transition: "all .2s linear",
        visibility: !showMenu ? "hidden" : "visible",
      }}>
        <FontAwesomeIcon className="icon" onClick={() => setShowMenu(!showMenu)} icon={faClose} fontSize={24} />
        <ul id="menu">
          <li><Link className="linkNav" to="/">Home</Link></li>
          <li><Link className="linkNav" to="/about">About Us</Link></li>
          {
            prodList.map((product) => {
              return (
                <li>
                  <Link to={"/product/" + product.productId}>
                    {product.name}
                  </Link>
                </li>
              );
            })
          }
          <li><Link to="/gallery" className="linkNav" onMouseEnter={() => setVisible(false)}>Gallery</Link></li>
          <li><Link to="/pricing" className="linkNav">Price List</Link></li>
          <li><Link to="/contact" className="linkNav">Contact Us</Link></li>
        </ul>
      </div>
    </>
  );
};

export default Header;
