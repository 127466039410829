import React, { useEffect } from "react";
import { Layout, Image } from "antd";
import "./styles.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Container } from "react-bootstrap";

const AboutUs = () => {
  
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div>
      <Header />
      <Layout className="layout">
        <Layout.Content>
          <div className="about-bg">
            <h1 level={2}>INTRODUCTION TO US</h1>
          </div>
          <div className="site-layout-content">
            <Container>
              <div style={{ textAlign: "justify" }}>
                <p className="mt-5">
                  <b>Maruti industries</b> is one of the leading packing products &
                  Adhesive Tape Manufacturers in India. We are dedicated to offering
                  premium-quality products at economical prices using modern
                  technologies to practice most efficiently. Our product range
                  involves Double Sided Tape, Masking Tape, Electrical Insulation
                  Tape, Box Packing Tape, Aluminum Tape, and Self-Adhesive in India.
                  Our erudite and skilled professionals do thorough research in the
                  market to produce these tapes under the set industry standards.
                  Being the top Packaging Tape Tolls Exporter, We manufacture these
                  products keeping in mind the requirements of our clients. We have
                  a team of highly-trained inspectors to persistently check the
                  quality of our entire offered range. Our products are subjected to
                  strict checking in our testing unit to ensure that our customers
                  collect only immaculate gamut. Our customer's contentment matters
                  to us, and so we deliver ordered consignments in time. Due to our
                  candid business practices, see-through dealings, and
                  customer-centric approach, We have been able to acquire big
                  clients across the country.
                </p>
                <p className="mt-5">
                <b>Maruti industries</b> is a leading manufacturer of high-quality adhesive tapes. With over a decade of experience in the industry, we have built a reputation for producing top-of-the-line products that meet the needs of customers across a wide range of industries.

                  Our state-of-the-art facility is equipped with the latest technology and machinery, allowing us to produce a wide range of tapes, including transparent tape, electrical tape, masking tape, and more. We use only the highest-quality raw materials to ensure that our products are both durable and reliable.
                </p>
                <p className="mt-5">
                  At <b>Maruti industries</b>, we pride ourselves on our commitment to customer satisfaction. Our dedicated team of experts is always on hand to provide technical support and advice, ensuring that our customers are completely satisfied with their purchase.

                  In addition to providing high-quality products, we also place a strong emphasis on sustainability. We are committed to reducing our environmental impact and strive to make our operations as eco-friendly as possible.

                  Whether you're an electrician, a contractor, or a DIY enthusiast, <b>Maruti industries</b> has the tape you need to get the job done. Contact us today to learn more about our products and how we can help you.
                </p>
              </div>
              <div className="text-center office-image-container">
                <Image src="/images/office.png" className="text-center" />
              </div>
            </Container>
          </div>
        </Layout.Content>
      </Layout>
      <Footer />
    </div>
  );
};

export default AboutUs;
