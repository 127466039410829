import { Breadcrumb, Button, Card, Image, Layout, Menu, Popconfirm } from "antd";
import { createElement, useEffect, useState } from "react";
import Products from "./products";
import SignIn from "./signin";
import { ProjectOutlined, LogoutOutlined } from '@ant-design/icons';
import Categories from "./categories";
import ProductPricing from "./pricing";
import "./styles.css"
import Orders from "./orders";
import Banner from "./banner";
import ContactRequest from "./contactRequest";
import Social from "./social";
const { Header, Sider, Content } = Layout;


function Admin() {
  const [loggedIn, setLogin] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("1");
  useEffect(() => {
    window.localStorage.getItem("IS_ADLN") == "a0d8945d46c7696c8c1bb9487e8a1bc4"
      ? setLogin(true)
      : setLogin(false);
  })

  const handleMenuClick = (ev) => {
    setActiveMenuItem(ev.key)
  }

  const menus = [
    {
      key: `1`,
      icon: createElement(ProjectOutlined),
      label: `Orders`
    },
    {
      key: `2`,
      icon: createElement(ProjectOutlined),
      label: `Product`
    },
    {
      key: `3`,
      icon: createElement(ProjectOutlined),
      label: `Category`
    },
    {
      key: `7`,
      icon: createElement(ProjectOutlined),
      label: `Social`
    },
    {
      key: `4`,
      icon: createElement(ProjectOutlined),
      label: `Pricing`
    },
    {
      key: `5`,
      icon: createElement(ProjectOutlined),
      label: `Banner`
    },
    {
      key: `6`,
      icon: createElement(ProjectOutlined),
      label: `Contact Requests`
    }
  ]

  const getActiveMenuPath = () => {
    return menus.find(f => f.key === activeMenuItem).label
  }

  const handleLogoutClick = () => {
    window.localStorage.removeItem("IS_ADLN");
    window.location.reload();
  }

  if (!loggedIn) {
    return (
      <SignIn />
    );
  } else {
    return (
      <Layout>
        <Header className="header">
          <div className="logo" >
            <Image src="./images/logo.png" preview={false} height={"60px"} style={{ width: "auto!important" }} />
          </div>
          <div className="greeting" >
            Welcome Admin
            <Popconfirm
              placement="bottomRight"
              title={'Are you sure you want to logout?'}
              onConfirm={handleLogoutClick}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" style={{ marginLeft: '5px' }} icon={<LogoutOutlined />}>
              </Button>
            </Popconfirm>
          </div>
        </Header>
        <Layout>
          <Sider width={200} className="site-layout-background" style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 60,
            bottom: 0,
          }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={[activeMenuItem]}
              defaultOpenKeys={['sub1']}
              style={{
                height: '100%',
                borderRight: 0,
              }}
              items={menus}
              onClick={handleMenuClick}
            />
          </Sider>
          <Content
            className="site-layout-background"
            style={{
              margin: 0,
              minHeight: 280,
              padding: '0 24px 24px',
              marginLeft: 200,
              height: "calc(100vh - 65px)",
              overflow: "auto"
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>{getActiveMenuPath()}</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ background: "#ffffff" }}>
              {activeMenuItem == "1" && <Orders />}
              {activeMenuItem == "2" && <Products />}
              {activeMenuItem == "3" && <Categories />}
              {activeMenuItem == "7" && <Social />}
              {activeMenuItem == "4" && <ProductPricing />}
              {activeMenuItem == "5" && <Banner />}
              {activeMenuItem == "6" && <ContactRequest />}
            </Card>
          </Content>
        </Layout>
      </Layout >
    )
  }
}

export default Admin;