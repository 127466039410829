/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Layout, Carousel, Image, Card, Breadcrumb, Table, Skeleton } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./styles.css";
import { getCarausalImages, getProductsList, getSocial } from "../../services/dataService";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faHeadphones, faPeopleGroup, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { getPricing } from "../../services/dataService";
import { faFacebook, faFacebookSquare, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [carausalImages, setCarausalImages] = useState([]);
  const [pricingData, setPricing] = useState([]);
  const [social, setSocial] = useState([]);
  const navigate = useNavigate();
  const fetchProducts = async () => {
    const newData = await getProductsList();
    setProducts(newData);
  };
  const fetchCarausal = async () => {
    let imgs = await getCarausalImages();
    setCarausalImages(imgs);
  }
  const fetchPricing = async () => {
    const newData = await getPricing();
    setPricing(newData);
  };
  const fetchSocial = async () => {
    const newData = await getSocial();
    setSocial(newData.products);
  };
  useEffect(() => {
    fetchCarausal();
    fetchProducts();
    fetchPricing();
    fetchSocial();
    window.scrollTo(0,0)
  }, []);

  const openProductDetail = (productId) => {
    console.log(productId);
    navigate("/product/" + productId);
  };

  const caruasalUi = (
    <Carousel infinite autoplay draggable>
      {carausalImages.map((iii) => {
        return <div>
          <Image
            preview={false}
            title=""
            width="100%"
            height="100%"
            className="banneris"
            style={{
              objectFit: "cover"
            }}
            src={iii.imageUrl}
            placeholder={
              <Image
                preview={false}
                src={iii.imageUrl}
                width="100%"
                className="banneris"
              />
            }
          />
        </div>
      })}
    </Carousel>
  )

  return (
    <div>
      <Header />
      <Layout className="layout">
        <Layout.Content>
          {caruasalUi}
          {carausalImages.length === 0 &&
            <div style={{width: 1024}}>
              <Skeleton.Image active className="skeleton" />
            </div>
          }
          <div className="main-container about-company">

          {/** Section of about company */}

            <div className="about-company-overlay">
            <Container>
              <div className="justify-content-center intro-div">
                  <h1 className="about-heading">
                    Introduction to the company
                  </h1>
                  <div className="intro-main-container">
                    <p className="intro-para">
                    Maruti industries is one of the leading packing products &
                    Adhesive Tape Manufacturers in India. We are dedicated to
                    offering premium-quality products at economical prices using
                    modern technologies to practice most efficiently. Our
                    product range involves Double Sided Tape, Masking Tape,
                    Electrical Insulation Tape, Box Packing Tape, Aluminum Tape,
                    and Self-Adhesive in India. Our erudite and skilled
                    professionals do thorough research in the market to produce
                    these tapes under the set industry standards. Being the top
                    Packaging Tape Tolls Exporter, We manufacture these products
                    keeping in mind the requirements of our clients.
                    <br /> <br />
                    We have a team of highly-trained inspectors to persistently
                    check the quality of our entire offered range. Our products
                    are subjected to strict checking in our testing unit to
                    ensure that our customers collect only immaculate gamut. Our
                    customer's contentment matters to us, and so we deliver
                    ordered consignments in time. Due to our candid business
                    practices, see-through dealings, and customer-centric
                    approach, We have been able to acquire big clients across
                    the country.
                    <br /> <br />
                    <a href="/about-us" className="read-more-btn">Read More 
                      <span className="read-more-icon"><FontAwesomeIcon icon={faChevronRight} /></span>
                    </a>
                    </p>
                    <img alt="owner" src="/images/madan.jpeg" className="madan-image" />
                  </div>
              </div>
            </Container>
            </div>

            {/** Section of counts */}

            <div className="white-bg">
              <Container>
                  <div className="amount-padding">
                    <Card bordered={false}>
                      <Card.Grid className="text-center amount-container">
                        <p className="amount">
                          256+
                        </p>
                        <span className="amount-desc"><FontAwesomeIcon icon={faSmile} /> Happy Clients</span>
                      </Card.Grid>
                      <Card.Grid className="text-center amount-container">
                        <p className="amount">
                          5+
                        </p>
                        <span className="amount-desc"><FontAwesomeIcon icon={faSitemap} /> Products</span>
                      </Card.Grid>
                      <Card.Grid className="text-center amount-container">
                        <p className="amount">
                          24/7
                        </p>
                        <span className="amount-desc"><FontAwesomeIcon icon={faHeadphones} /> Support</span>
                      </Card.Grid>
                      <Card.Grid className="text-center amount-container">
                        <p className="amount">
                          20+
                        </p>
                        <span className="amount-desc"><FontAwesomeIcon icon={faPeopleGroup} /> Team Workers</span>
                      </Card.Grid>
                    </Card>
                  </div>
              </Container>
            </div>

            {/** Section of products */}

            <div className="white-bg product-bg">
            <div className="about-company-overlay">
              <Container className="white-bg product-outer-container">
                <h1 className="product-we-have">Products we have</h1>
                <Row className="white-bg padding-product">
                  <Col
                    md={12}
                    sm={11}
                    xs={11}
                    title="Products"
                    className="products-container"
                  >
                    {/* <span className="product-heading">
                      Products We have
                    </span> */}
                    <Card style={{ border: 0 }}>
                      {products.map((product) => {
                        return (
                          <>
                            <Card.Grid
                              className="round-card card-custom"
                              title={product.name}
                              onClick={() => {
                                openProductDetail(product.productId);
                              }}
                            >
                              <Image
                                src={product.image}
                                preview={false}
                              />
                              {/* <h3 className="product-item-heading">
                                {product.name}
                              </h3> */}
                            </Card.Grid>
                          </>
                        );
                      })}
                      <Card.Grid hidden className="round-card" title="Masking Tapes">
                        <Image
                          src="./images/masking-tape.jpeg"
                          width={200}
                          height={200}
                          preview={false}
                        />
                        <h3 className="product-item-heading">Masking Tapes</h3>
                      </Card.Grid>
                    </Card>
                  </Col>
                </Row>
              </Container>
              </div>
            </div>

            {/** Section of price list */}
            
          <div className="site-layout-content">
          <Container>
            {/* <Table bordered columns={columns} dataSource={pricingData} pagination={false} scroll={{
              y: 600,
              x: 400
            }} /> */}
            <h2 className="text-center price-heading">Price List</h2>
            <div className="mobile-hidden">
            <table className="table table-striped table-hover table-bordered price-table" style={{borderColor: '#222'}}>
            <thead>
              <tr style={{backgroundColor: '#70c04f', color: '#222'}}>
                <th width="50" style={{padding: 10}} scope="col">#</th>
                <th width="100" style={{padding: 10}} scope="col">METERS</th>
                <th width="150" style={{padding: 10}} scope="col">AMOUNT (GST EXC.)</th>
                <th width="150" style={{padding: 10}} scope="col">TAXABLE AMOUNT</th>
              </tr>
            </thead>
            <tbody>
            {pricingData?.map((item, index) => {
              return (
                <tr style={{backgroundColor: index%2 === 0 ? '#ffd2d3' : 'rgb(163 174 237)'}}>
                  <th className="pricing-td" scope="row">{index + 1}</th>
                  <td className="pricing-td">{item.meters}</td>
                  <td className="pricing-td">{item.taxAmount}</td>
                  <td className="pricing-td">{item.boxRate}</td>
                </tr>
              )
            })}
            </tbody>
            </table>
            </div>
            <div>
              <br/><br/>
                <h5 style={{fontWeight: 'bold', marginBottom: 30}}>Terms & Conditions: </h5>
                <p className="priceTandC">Freight: Extra</p>
                <p className="priceTandC">Payment: Advance before dispatch</p>
                <p className="priceTandC">Colour: 20% Additional</p>
                <p className="priceTandC">Printing: Cylander charge - Rs 6000 /- per colour Additional</p>
                <p className="priceTandC">If 2.5" Rs 250/- extra per box</p>
                <p className="priceTandC">0.5": 288 peice in box</p>
                <p className="priceTandC">1" : 144 peice in box</p>
                <p className="priceTandC">2" : 72 peice in box</p>
                <p className="priceTandC">3" : 48 peice in box</p>
                <p style={{color: '#027f36'}} className="priceTandC">*All meter, All inches available</p>
                <br/>
                <br/>
            </div>
            </Container>
          </div>

          {/** Section of social */}
            
          <div className="main-container btm-fb">
            <div className="about-company-overlay">
            <Container className="white-bg product-outer-container">
              <h1 className="product-we-have">
                Social Wall 
                <a target="_blank" href="https://facebook.com/mitape9"><FontAwesomeIcon className="icon-spin" icon={faFacebook} color="#fff" /></a>
                <a target="_blank" href="https://instagram.com/mitape9"><FontAwesomeIcon className="icon-spin" icon={faInstagram} color="#fff" /></a>
              </h1>
              <div className="social-products">
                <Card style={{ border: 0 }}>
                {social?.map((item) => {
                  return(
                    <Card.Grid className="image-card">
                      <Image src={item.image} />
                    </Card.Grid>
                  )
                })}
                </Card>
              </div>

            </Container>
            </div>
            </div>
          </div>
        </Layout.Content>
      </Layout>
      <Footer />
    </div>
  );
};

export default Home;
