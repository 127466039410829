import React, { useEffect } from "react";
import { Layout, Card } from "antd";
import "./styles.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour, faLocationDot, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const ContactUs = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div>
      <Header />
      <Layout className="layout">
        <Layout.Content>
          <div className="contact-bg">
            <h1 level={2}>CONTACT US</h1>
          </div>
          <div className="site-layout-content">
            <Container className="text-center">
              <Card bordered={false} className="mt-5 text-center">
                <Card.Grid className="location-container me-5 mb-5">
                  <div>
                    <span className="location">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                    <h4 className="address-text">Meet Us</h4>
                    <p className="address-main">Old Line, Gangashahar, Bikaner, Rajasthan, 334401 (India)</p>
                  </div>
                </Card.Grid>
                <Card.Grid className="location-container mb-5">
                  <div>
                    <span className="location" style={{paddingTop: 12, paddingBottom: 12}}>
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </span>
                    <h4 className="address-text">WhatsApp Us</h4>
                    <p className="address-main">
                      <a  style={{color: '#222', textDecoration: 'none'}}
                        href="https://api.whatsapp.com/send?phone=918387887888"
                        target="_blank" rel="noreferrer"
                      >
                        +91-8387887888
                      </a>
                    </p>
                  </div>
                </Card.Grid>
                <Card.Grid className="location-container me-5 mb-5">
                  <div>
                    <span className="location">
                      <FontAwesomeIcon icon={faMobileAlt} />
                    </span>
                    <h4 className="address-text">Call Us</h4>
                    <p className="address-main"><a style={{color: '#222', textDecoration: 'none'}} href="tel:+918387887888">+91-8387887888</a> / <a style={{color: '#222', textDecoration: 'none'}} href="tel:+918104792395">+91-8104792395</a></p>
                  </div>
                </Card.Grid>
                <Card.Grid className="location-container mb-5">
                  <div>
                    <span className="location" style={{paddingTop: 13, paddingBottom: 13, fontSize: 24}}>
                      <FontAwesomeIcon icon={faClockFour} />
                    </span>
                    <h4 className="address-text">Reach Us</h4>
                    <p className="address-main">Mon-Sat: 08:00AM - 06:00PM</p>
                  </div>
                </Card.Grid>
              </Card>
            </Container>
            <Container>
              <div>
                <div>
                  <iframe width="100%" height={500} id="gmap_canvas" src="https://maps.google.com/maps?q=MI%20Tape,%20Old%20Lane,%20Gangashahar,%20Bikaner,%20Rajasthan,%20India&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} />
                </div></div>
            </Container>
          </div>
        </Layout.Content>
      </Layout>
      <Footer />
    </div>
  );
};

export default ContactUs;
