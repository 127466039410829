import { Button, Col, Form, Image, Modal, Row, Upload } from "antd";
import { useEffect, useState } from "react";
import { addProduct, addSocialPost, deleteProduct, getProducts, getSocial } from "../../../services/dataService";
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

function Social() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [productData, setFormData] = useState({
    productId: "",
    name: "",
    categoryId: "",
    sizes: [],
    sizeIds: [],
    imageData: {}
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rawProductData, setProductRawData] = useState({
    productId: "",
    name: "",
    categoryId: "",
    sizes: [],
    sizeIds: [],
    imageData: {}
  });
  const [deleteDialogVisible, toggleDeleteDialog] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);

  const [selectedPricing, setPricingData] = useState([]);

  const [productForm] = Form.useForm();
  const productNameValue = Form.useWatch('name', productForm);
  const productDescValue = Form.useWatch('description', productForm);
  const productCategoryIdValue = Form.useWatch('categoryId', productForm);
  const productSizesValue = Form.useWatch('sizes', productForm);

  const fetchSocial = async () => {
    const newData = await getSocial();
    setProducts(newData.products);
  };

  useEffect(() => {
    fetchSocial()
  }, []);

  const toggleProductForm = (event) => {
    resetProductForm(true);
    setFormVisible(!formVisible);
  }

  const resetProductForm = (fullReset) => {
    if (!fullReset && productData.productId) {
      setUploadedFiles([
        {
          uid: '1',
          name: rawProductData.imageData.name,
          status: 'done',
          url: rawProductData.imageData.data,
        }
      ])
    } else {
      productForm.resetFields();
      setUploadedFiles([]);
    }
  }

  const cancelProductForm = event => {
    setFormVisible(false);
  }

  const submitProductForm = async (event) => {
    if (productData.productId) {
      fetchSocial();
      toggleProductForm();
    } else {
      // add social post
      let finalData = {
        imageData: productData.imageData
      }
      const newProdRes = await addSocialPost(finalData);
      if (newProdRes.id) {
        fetchSocial();
        toggleProductForm();
      } else {
        // show error alert
      }
    }
  }

  const handleUpload = (file, filelist) => {
    const reader = new FileReader();

    reader.onload = e => {
      productData.imageData.data = e.target.result
    };
    reader.readAsText(file);
    return false;
  }

  const onFileChange = (file) => {
    if (file.fileList && file.fileList.length) {
      let rff = file.fileList[0];
      productData.imageData = {
        name: rff.name,
        file: rff,
      }
      setUploadedFiles(file.fileList);
    } else {
      setUploadedFiles([])
    }
  }

  const handleDeleteDialogClose = (event) => {
    setDeleteProductId(null);
    toggleDeleteDialog(false);
  }
  const deleteProductData = (event) => {
    deleteProduct(deleteProductId).then(f => {
      toggleDeleteDialog(false);
      fetchSocial();
    });
  }


  const formUi = (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <h5>Add New Social Post</h5>
        <div>
          <CloseOutlined style={{ position: "relative", top: "1px", cursor: "pointer" }} onClick={toggleProductForm} />
        </div>
      </div>
      <Form
        labelCol={{ span: 20 }}
        wrapperCol={{ span: 20 }}
        layout="vertical"
        form={productForm}
      >
        <Row>
          <Col xs={24} md={12}>
            <Form.Item label="Image" valuePropName="fileList">
              <Upload
                accept="image/*"
                beforeUpload={handleUpload}
                listType="picture-card"
                maxCount={1}
                onChange={onFileChange}
                fileList={uploadedFiles}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ display: "flex", marginTop: "24px", justifyContent: "space-between" }}>
        <div>
          <Button onClick={() => { resetProductForm(false) }}>
            Reset
          </Button>
        </div>
        <div>
          <Button onClick={cancelProductForm}>
            Cancel
          </Button>
          <Button type="primary" onClick={submitProductForm} style={{ marginLeft: "10px" }}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );

  const tableUi = (
    <>
      <Col xs={24} md={24}>
        <Row style={{ marginBottom: "24px" }} >
          <Col xs={24} md={12} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={toggleProductForm}>
              + Upload
            </Button>
          </Col>
        </Row>
        <div>
          {products.map((item) => {
            return(
                <Image src={item.image} preview={false} style={{width: 150, height: 150, marginRight: 40}} />
            )
          })}
        </div>
      </Col>
    </>
  );

  return (
    <>
      {!formVisible ? tableUi : formUi}
      <Modal
        title="Delete Product"
        open={deleteDialogVisible}
        onOk={deleteProductData}
        onCancel={handleDeleteDialogClose}
        okText="Delete"
      >
        <p>Are you sure you want to delete the selected product?</p>
      </Modal>
    </>
  )
}

export default Social;
