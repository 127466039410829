import React, { useEffect, useState } from "react";
import { Card, Image, Layout } from "antd";
import "./styles.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { getSocial } from "../../services/dataService";
import { Container } from "react-bootstrap";

const Gallery = () => {
  const [social, setSocial] = useState([]);
  
  const fetchSocial = async () => {
    const newData = await getSocial();
    setSocial(newData.products);
  };
  useEffect(() => {
    fetchSocial()
    window.scrollTo(0,0)
  }, []);

  return (
    <div>
      <Header />
      <Layout className="layout">
        <Layout.Content>
        <div className="price-bg">
            <h1 level={2}>GALLERY</h1>
          </div>
          <div className="site-layout-content">
            <Container>
              <div className="social-products">
                <Card style={{ border: 0 }}>
                {social?.map((item) => {
                  return(
                    <Card.Grid className="image-card">
                      <Image src={item.image} />
                    </Card.Grid>
                  )
                })}
                </Card>
              </div>
            </Container>
          </div>
        </Layout.Content>
      </Layout>
      <Footer />
    </div>
  );
};

export default Gallery;
