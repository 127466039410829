import { Button, Col, Form, InputNumber, Modal, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { addPricing, deletePricing, getPricing, updatePricing } from "../../../services/dataService";
import { EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import Search from "antd/lib/input/Search";

function ProductPricing() {
  const [pricing, setPricing] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [pricingData, setFormData] = useState({
    pricingId: "",
    meters: "",
    taxAmount: "",
    boxRate: "",
  });
  const [deleteDialogVisible, toggleDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [pricingForm] = Form.useForm();
  const metersValue = Form.useWatch('meters', pricingForm);
  const taxAmountValue = Form.useWatch('taxAmount', pricingForm);
  const boxRateValue = Form.useWatch('boxRate', pricingForm);

  const fetchPricing = async () => {
    const newData = await getPricing();
    setPricing(newData);
  };
  useEffect(() => {
    fetchPricing()
  }, []);

  const toggleForm = (event) => {
    resetForm();
    setFormVisible(!formVisible);
  }

  const resetForm = () => {
    console.log(pricingData)
    if (pricingData.pricingId) {
      pricingForm.setFieldsValue({
        meters: pricingData.meters,
        taxAmount: pricingData.taxAmount,
        boxRate: pricingData.boxRate
      })
    } else {
      pricingForm.resetFields();
      pricingForm.setFieldsValue({
        meters: "",
        taxAmount: "",
        boxRate: ""
      })
      setFormData({
        pricingId: "",
        meters: "",
        taxAmount: "",
        boxRate: ""
      })
    }
  }

  const cancelForm = event => {
    setFormVisible(false);
  }

  const submitForm = async (event) => {
    if (pricingData.pricingId) {
      // update product
      let finalData = {
        pricingId: pricingData.pricingId,
        meters: metersValue,
        taxAmount: taxAmountValue,
        boxRate: boxRateValue
      }
      console.log(finalData);
      const prodUpdRes = await updatePricing(finalData)
      fetchPricing();
      toggleForm();
    } else {
      // add product
      let finalData = {
        meters: metersValue,
        taxAmount: taxAmountValue,
        boxRate: boxRateValue
      }
      console.log(finalData);
      const newProdRes = await addPricing(finalData);
      if (newProdRes.id) {
        fetchPricing();
        toggleForm();
      } else {
        // show error alert
      }
    }
    setFormData({
      pricingId: "",
      meters: "",
      taxAmount: "",
      boxRate: ""
    })
  }

  const handleDeleteClick = (pricingId) => {
    setDeleteId(pricingId);
    toggleDeleteDialog(true);
  }
  const handleDeleteDialogClose = (event) => {
    setDeleteId(null);
    toggleDeleteDialog(false);
  }
  const deleteData = (event) => {
    deletePricing(deleteId).then(f => {
      toggleDeleteDialog(false);
      fetchPricing();
    });
  }

  const handleEditClick = (pricingId) => {
    let pricingDetail = pricing.find(f => f.pricingId == pricingId);
    if (!pricingDetail) {
      return;
    }
    console.log(pricingDetail)
    setFormVisible(true);
    setFormData({
      pricingId: pricingDetail.pricingId,
      meters: pricingDetail.meters,
      taxAmount: pricingDetail.taxAmount,
      boxRate: pricingDetail.boxRate
    })
    pricingForm.setFieldsValue({
      meters: pricingDetail.meters,
      taxAmount: pricingDetail.taxAmount,
      boxRate: pricingDetail.boxRate
    })
  }

  const columns = [
    {
      title: 'Meters',
      key: 'meters',
      render: (rec) => (
        <>
          <a>{rec.meters}</a>
        </>
      ),
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmount',
      key: 'taxAmount',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Box Rate',
      dataIndex: 'boxRate',
      key: 'boxRate',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (rec) => (
        <Space size="middle">
          <a><EditOutlined style={{ color: "#6a89a1" }} onClick={(e) => {
            handleEditClick(rec.pricingId);
          }} /></a>
          <a><DeleteOutlined style={{ color: "#f44336" }} onClick={(ev) => {
            handleDeleteClick(rec.pricingId);
          }} /></a>
        </Space>
      ),
    },
  ];

  const formUi = (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <h5>Add New Pricing</h5>
        <div>
          <CloseOutlined style={{ position: "relative", top: "1px", cursor: "pointer" }} onClick={toggleForm} />
        </div>
      </div>
      <Form
        labelCol={{ span: 20 }}
        wrapperCol={{ span: 20 }}
        layout="vertical"
        form={pricingForm}
      >
        <Row>
          <Col xs={24} md={10}>
            <Form.Item label="Meters" name="meters">
              <InputNumber placeholder="Enter Meters" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Tax Amount" name="taxAmount">
              <InputNumber placeholder="Enter Tax Amount" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Box Rate" name="boxRate">
              <InputNumber placeholder="Enter Box Rate" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ display: "flex", marginTop: "24px", justifyContent: "space-between" }}>
        <div>
          <Button onClick={() => { resetForm(false) }}>
            Reset
          </Button>
        </div>
        <div>
          <Button onClick={cancelForm}>
            Cancel
          </Button>
          <Button type="primary" onClick={submitForm} style={{ marginLeft: "10px" }}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );

  const tableUi = (
    <>
      <Row style={{ marginBottom: "24px" }} >
        <Col xs={24} md={12}>
          <Search placeholder="Search" style={{ width: 200 }} />
        </Col>
        <Col xs={24} md={12} style={{ textAlign: "right" }}>
          <Button type="primary" icon={<PlusOutlined />} onClick={toggleForm}>
            New
          </Button>
        </Col>
      </Row>
      <Table columns={columns} dataSource={pricing} />
    </>
  );

  return (
    <>
      {!formVisible ? tableUi : formUi}
      <Modal
        title="Delete Pricing"
        open={deleteDialogVisible}
        onOk={deleteData}
        onCancel={handleDeleteDialogClose}
        okText="Delete"
      >
        <p>Are you sure you want to delete the selected pricing data?</p>
      </Modal>
    </>
  )
}

export default ProductPricing;