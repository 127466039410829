import { Button, Col, Form, Image, Input, InputNumber, Layout, Menu, Modal, Row, Select, Space, Table, Tag, Upload } from "antd";
import { useEffect, useState } from "react";
import { addProduct, deleteProduct, getProducts, updateProduct } from "../../../services/dataService";
import { EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import Search from "antd/lib/input/Search";
import TextArea from "antd/lib/input/TextArea";
const { Header, Sider, Content } = Layout;

function Products() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [productData, setFormData] = useState({
    productId: "",
    name: "",
    categoryId: "",
    sizes: [],
    sizeIds: [],
    imageData: {}
  });
  const [productImage, setProdImage] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rawProductData, setProductRawData] = useState({
    productId: "",
    name: "",
    categoryId: "",
    sizes: [],
    sizeIds: [],
    imageData: {}
  });
  const [deleteDialogVisible, toggleDeleteDialog] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);

  const [selectedPricing, setPricingData] = useState([]);

  const [productForm] = Form.useForm();
  const productNameValue = Form.useWatch('name', productForm);
  const productDescValue = Form.useWatch('description', productForm);
  const productCategoryIdValue = Form.useWatch('categoryId', productForm);
  const productSizesValue = Form.useWatch('sizes', productForm);
  const metersValue = Form.useWatch('meters', productForm);
  const taxAmountValue = Form.useWatch('taxAmount', productForm);
  const boxRateValue = Form.useWatch('boxRate', productForm);
  const [curPricingValue, setCurPricingValue] = useState(null)

  const fetchProducts = async () => {
    const newData = await getProducts();
    setProducts(newData.products);
    let cats = [];
    newData.categories.forEach(element => {
      cats.push({
        value: element.categoryId,
        label: element.title
      })
    });
    let sizes = [];
    newData.sizes.forEach(element => {
      sizes.push({
        value: element.sizeId,
        label: element.size
      })
    });
    setCategories(cats);
    setSizes(sizes);
  };
  useEffect(() => {
    fetchProducts()
  }, []);

  const toggleProductForm = (event) => {
    resetProductForm(true);
    setFormVisible(!formVisible);
  }

  const resetProductForm = (fullReset) => {
    if (!fullReset && productData.productId) {
      productForm.setFieldsValue({
        name: productData.name,
        description: productData.description,
        categoryId: productData.categoryId ? productData.categoryId : categories && categories[0] ? categories[0].categoryId : null,
        sizes: productData.sizeIds,
        meters: null,
        taxAmount: null,
        boxRate: null
      });
      setCurPricingValue(null);
      setPricingData(productData.pricing);
      setUploadedFiles([
        {
          uid: '1',
          name: rawProductData.imageData.name,
          status: 'done',
          url: rawProductData.imageData.data,
        }
      ])
    } else {
      productForm.resetFields();
      setFormData({
        name: "",
        description: "",
        categoryId: categories && categories[0] ? categories[0].categoryId : null,
        sizeIds: [],
        imageData: {}
      });
      setUploadedFiles([])
      setPricingData([]);
    }
  }

  const cancelProductForm = event => {
    setFormVisible(false);
  }

  const submitProductForm = async (event) => {
    if (productData.productId) {
      // update product
      let finalData = {
        productId: productData.productId,
        name: productNameValue,
        description: productDescValue,
        categoryId: productCategoryIdValue,
        sizeIds: productSizesValue,
        imageData: productData.imageData
      }
      const prodUpdRes = await updateProduct(finalData)
      fetchProducts();
      toggleProductForm();
    } else {
      // add product
      let finalData = {
        name: productNameValue,
        description: productDescValue,
        categoryId: productCategoryIdValue,
        sizeIds: productSizesValue,
        imageData: productData.imageData
      }
      const newProdRes = await addProduct(finalData);
      if (newProdRes.id) {
        fetchProducts();
        toggleProductForm();
      } else {
        // show error alert
      }
    }
  }

  const handleUpload = (file, filelist) => {
    const reader = new FileReader();

    reader.onload = e => {
      productData.imageData.data = e.target.result
    };
    reader.readAsText(file);
    return false;
  }

  const onFileChange = (file) => {
    if (file.fileList && file.fileList.length) {
      let rff = file.fileList[0];
      productData.imageData = {
        name: rff.name,
        file: rff,
      }
      setUploadedFiles(file.fileList);
    } else {
      setUploadedFiles([])
    }
  }

  const handleDeleteClick = (productId) => {
    setDeleteProductId(productId);
    toggleDeleteDialog(true);
  }
  const handleDeleteDialogClose = (event) => {
    setDeleteProductId(null);
    toggleDeleteDialog(false);
  }
  const deleteProductData = (event) => {
    deleteProduct(deleteProductId).then(f => {
      toggleDeleteDialog(false);
      fetchProducts();
    });
  }

  const handleEditClick = (productId) => {
    let product = products.find(f => f.productId == productId);
    if (!product) {
      return;
    }
    setFormVisible(true);

    productForm.setFieldsValue({
      name: product.name,
      description: product.description,
      categoryId: product.categoryId ? product.categoryId : categories && categories[0] ? categories[0].categoryId : null,
      sizes: product.sizeIds,
    });
    setFormData({
      productId: productId,
      name: product.name,
      description: product.description,
      categoryId: product.categoryId ? product.categoryId : categories && categories[0] ? categories[0].categoryId : null,
      sizeIds: product.sizeIds,
      imageData: {
        name: product.imageUrl,
        data: product.image
      },
      pricing: []
    });
    setProductRawData({
      imageData: {
        name: product.imageUrl,
        data: product.image
      }
    });
    setUploadedFiles([
      {
        uid: '1',
        name: product.imageUrl,
        status: 'done',
        url: product.image,
      }
    ])
  }

  const handleEditClickPricing = (pricingId) => {
    let pricingData = selectedPricing.find(f => f.pricingId === pricingId);
    setCurPricingValue(pricingId);
    productForm.setFieldsValue({
      meters: pricingData.meters,
      taxAmount: pricingData.taxAmount,
      boxRate: pricingData.boxRate
    })
  }

  const handleDeleteClickPricing = (pricingId) => {
    setPricingData(selectedPricing.filter(f => f.pricingId !== pricingId));
  }

  const addPricingRec = () => {
    if (!metersValue || !taxAmountValue || !boxRateValue) {
      return;
    }
    if (curPricingValue) {
      let curPrices = selectedPricing.filter(f => true);
      let curPrice = curPrices.find(f => f.pricingId === curPricingValue);
      curPrice.meters = metersValue;
      curPrice.taxAmount = taxAmountValue;
      curPrice.boxRate = boxRateValue;
      setPricingData(curPrices)
    } else {
      setPricingData([...selectedPricing, {
        pricingId: Date.now(),
        meters: metersValue,
        taxAmount: taxAmountValue,
        boxRate: boxRateValue
      }])
    }
    productForm.setFieldsValue({
      meters: null,
      taxAmount: null,
      boxRate: null
    });
    setCurPricingValue(null);
  }

  const columns = [
    {
      title: 'Product',
      key: 'name',
      render: (rec) => (
        <>
          <Image
            width={60}
            src={rec.image}
            style={{ marginRight: '5px' }}
          />
          <a>{rec.name}</a>
        </>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      key: 'categoryName',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Size',
      key: 'sizeNames',
      dataIndex: 'sizeNames',
      render: (sizes) => (
        <>
          {sizes.map((tag) => {
            let color = 'green';
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (rec) => (
        <Space size="middle">
          <a><EditOutlined style={{ color: "#6a89a1" }} onClick={(e) => {
            handleEditClick(rec.productId);
          }} /></a>
          <a><DeleteOutlined style={{ color: "#f44336" }} onClick={(ev) => {
            handleDeleteClick(rec.productId);
          }} /></a>
        </Space>
      ),
    },
  ];

  const pricingColumns = [
    {
      title: 'Meters',
      dataIndex: 'meters',
      key: 'meters',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmount',
      key: 'taxAmount',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Box Rate',
      dataIndex: 'boxRate',
      key: 'boxRate',
      render: (text) => <a>{text}</a>,
    },
    {
      title: '',
      key: 'action',
      render: (rec) => (
        <Space size="middle">
          <a><EditOutlined style={{ color: "#6a89a1" }} onClick={(e) => {
            handleEditClickPricing(rec.pricingId);
          }} /></a>
          <a><DeleteOutlined style={{ color: "#f44336" }} onClick={(ev) => {
            handleDeleteClickPricing(rec.pricingId);
          }} /></a>
        </Space>
      ),
    },
  ];

  const formUi = (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <h5>Add New Product</h5>
        <div>
          <CloseOutlined style={{ position: "relative", top: "1px", cursor: "pointer" }} onClick={toggleProductForm} />
        </div>
      </div>
      <Form
        labelCol={{ span: 20 }}
        wrapperCol={{ span: 20 }}
        layout="vertical"
        form={productForm}
      >
        <Row>
          <Col xs={24} md={10}>
            <Form.Item label="Name" name="name">
              <Input placeholder="Enter product name" />
            </Form.Item>
            <Form.Item label="Category" name="categoryId">
              <Select options={categories} placeholder="Select Category">
              </Select>
            </Form.Item>
            <Form.Item label="Size" name="sizes">
              <Select options={sizes} placeholder="Select Sizes" mode="multiple">
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Description" name="description">
              <TextArea rows={4} placeholder={"Enter product description"} />
            </Form.Item>
            <Form.Item label="Image" valuePropName="fileList">
              <Upload
                accept="image/*"
                beforeUpload={handleUpload}
                listType="picture-card"
                maxCount={1}
                onChange={onFileChange}
                fileList={uploadedFiles}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ display: "flex", marginTop: "24px", justifyContent: "space-between" }}>
        <div>
          <Button onClick={() => { resetProductForm(false) }}>
            Reset
          </Button>
        </div>
        <div>
          <Button onClick={cancelProductForm}>
            Cancel
          </Button>
          <Button type="primary" onClick={submitProductForm} style={{ marginLeft: "10px" }}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );

  const tableUi = (
    <>
      <Row style={{ marginBottom: "24px" }} >
        <Col xs={24} md={12}>
          <Search placeholder="Search" style={{ width: 200 }} />
        </Col>
        <Col xs={24} md={12} style={{ textAlign: "right" }}>
          <Button type="primary" icon={<PlusOutlined />} onClick={toggleProductForm}>
            New
          </Button>
        </Col>
      </Row>
      <Table columns={columns} dataSource={products} />
    </>
  );

  return (
    <>
      {!formVisible ? tableUi : formUi}
      <Modal
        title="Delete Product"
        open={deleteDialogVisible}
        onOk={deleteProductData}
        onCancel={handleDeleteDialogClose}
        okText="Delete"
      >
        <p>Are you sure you want to delete the selected product?</p>
      </Modal>
    </>
  )
}

export default Products;