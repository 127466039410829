import { Button, Carousel, Col, Form, Image, InputNumber, Modal, Row, Space, Table, Upload } from "antd";
import { useEffect, useState } from "react";
import { addPricing, deleteImageFromFB, getCarausalImages, uploadImage } from "../../../services/dataService";
import { PlusOutlined } from '@ant-design/icons';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function Banner() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [deleteDialogVisible, toggleDeleteDialog] = useState(false);
  const [deleteFile, setDeleteId] = useState(null);


  const fetchCarausal = async () => {
    let imgs = await getCarausalImages();
    let allImgs = []
    imgs.forEach((im, index) => {
      allImgs.push({
        uid: index + 1,
        name: im.name,
        status: 'done',
        url: im.imageUrl
      })
    })
    setUploadedFiles(allImgs)
  }
  useEffect(() => {
    fetchCarausal()
  }, []);

  const handleUpload = (file, filelist) => {
    const reader = new FileReader();

    reader.onload = e => {
    };
    reader.readAsText(file);
    return false;
  }

  const onFileChange = (file) => {
    if (typeof file.file.uid == "string") {
      // new file upload
      uploadImage(file.file).then(f => {
        console.log(f);
      })
    }
    if (file.fileList && file.fileList.length) {
      setUploadedFiles(file.fileList);
    } else {
      setUploadedFiles([])
    }
  }

  const handleCancel = () => {
    setPreviewOpen(false)
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleDeleteDialogClose = (event) => {
    setDeleteId(null);
    toggleDeleteDialog(false);
  }
  const removeFile = async (file) => {
    setDeleteId(file)
    toggleDeleteDialog(true);
    return false;
  };
  const deleteImage = async () => {
    if (deleteFile) {
      await deleteImageFromFB(deleteFile.name);
      setUploadedFiles(uploadedFiles.filter(f => f.name !== deleteFile.name));
      toggleDeleteDialog(false);
    }
  };

  return (
    <>
      <Upload
        accept="image/*"
        beforeUpload={handleUpload}
        listType="picture-card"
        onChange={onFileChange}
        fileList={uploadedFiles}
        onPreview={handlePreview}
        onRemove={removeFile}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
      <Modal
        title="Delete Image"
        open={deleteDialogVisible}
        onOk={deleteImage}
        onCancel={handleDeleteDialogClose}
        okText="Delete"
      >
        <p>Are you sure you want to delete the selected image?</p>
        <Image
          width={200}
          src={deleteFile?.url}
        />
      </Modal>
    </>
  )
}

export default Banner;