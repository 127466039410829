import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import Admin from "./pages/admin";
import Pricing from "./pages/pricing";
import ProductDetail from "./pages/productDetail";
import ContactUs from "./pages/contactUs";
import Gallery from "./pages/gallery";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route key="1" path="/" element={<Home />} />
        <Route key="2" path="/about" element={<AboutUs />} />
        <Route key="3" path="/tech" element={<Admin />} />
        <Route key="4" path="/pricing" element={<Pricing />} />
        <Route key="5" path="/product/:id" element={<ProductDetail />} />
        <Route key="6" path="*" element={<Navigate to="/" />} />
        <Route key="7" path="/contact" element={<ContactUs />} />
        <Route key="8" path="/gallery" element={<Gallery />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
