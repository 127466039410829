import React from "react";
import { Image, Layout } from "antd";
import "./styles.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
      <div>
        <Layout className="layout">
          <Layout.Footer className="footer-main-container">
            <Container>
              <div className="footer-container">
              <div className="footer-section-four">
                  <Image preview={false} src="/images/logo.png" />
                  <address>Old line, Gangashahar, Bikaner,
                    <br/>Rajasthan, India - 334401
                  </address>
                  <a href='https://fb.com' className="fb-icon">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a href='https://instagram.com' className="insta-icon">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a href='https://twitter.com' className="twitter-icon">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href='https://youtube.com' className="yt-icon">
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </div>
                <div className="footer-section-one">
                  <h4 className="footer-link">Links</h4>
                  <div>
                    <Link to="/">- Home</Link>
                    <Link to="/pricing">- Pricing</Link>
                    <Link to="/gallery">- Gallery</Link>
                    <Link to="/about">- About Us</Link>
                    <Link to="/contact">- Contact Us</Link>
                  </div>
                </div>
                <div className="footer-section-two">
                  <h4 className="footer-link">Why Us</h4>
                  <p>Maruti industries is one of the leading packing products & Adhesive Tape Manufacturers in India. We are dedicated to offering premium-quality products at economical prices using modern technologies to practice most efficiently.</p>
                </div>
                <div className="footer-section-three">
                  <h4 className="footer-link">Contact Us</h4>
                  <address>Old line, Gangashahar, Bikaner,
                    <br/>Rajasthan, India - 334401</address>
                    <p>
                      <span className="phone-text">Phone:</span> +91-8387887888 <br/><span style={{marginLeft: 52}}>+91-8104792395</span>
                      <br/>
                      <br/>
                      <span className="phone-text">Email:</span> marutiindustires@gmail.com
                    </p>
                </div>
              </div>
            </Container>
            <div className="copyright">
              <span className="maruti-name">Maruti Industries</span> All Rights Reserved ©2023
              <p style={{padding: 0, margin: 0}}>Designed by <b>Shubham</b></p>
            </div>
          </Layout.Footer>
        </Layout>
      </div>
  );
};

export default Footer;
